/* global fsLoader, headerPopup, basket, fsAssets */

(function (document, fsLoader, headerPopup, basket) {
  var navlogo = $('#navlogo');
  var scrollup = $('#scrollup');

  $(document).on("click", "[data-fancybox]", function () {
    if ("undefined" !== typeof $.fancybox) {
      return;
    }
    var button = this;
    fsLoadFancyBox(function () {
      $(button).trigger("click");
    });
    return false;
  });

  $(document).on("click", ".quickview", function () {
    if ("undefined" !== typeof qview) {
      return;
    }
    var button = this;
    fsLoadFancyBox(function () {
      fsLoader.addStyles([fsAssets.styles.quickview], function () {
        fsLoader.addScripts([fsAssets.scripts.quickview], function () {
          setTimeout(function () {
            $(button).trigger("click");
          }, 300);
        });
      });
    });
  });

  $(window).scroll(function () {
    var scrollTop = $(document).scrollTop();

    if (scrollTop > 120) {
      navlogo.addClass('logo_vis');
    } else {
      navlogo.removeClass('logo_vis');
    }

    if (scrollup) {
      var dHeight = $(document).height();
      var wHeight = $(window).height();

      if ((scrollTop > 300) & (wHeight < (dHeight - scrollTop))) {
        scrollup.fadeIn('slow');
      } else {
        scrollup.fadeOut('slow');
      }
    }
  });

  if (scrollup) {
    scrollup.find('img').mouseover(function () {
      $(this).animate({opacity: 0.9}, 300);
    }).mouseout(function () {
      $(this).animate({opacity: 0.5}, 300);
    }).click(function () {
      $("html,body").animate({scrollTop: 0}, 800);
      return false;
    });
  }

  $(".showAll").click(function () {
    $(this).closest('ul').find('li').not('.showAll').fadeIn(100);
    //$(this).closest('li').remove();
    $(this).closest('li').hide();
  });

  $(".two-level span").click(function () {
    $(this).parent("li").toggleClass("vis");
  });

  $(".unislider .unislider-button").click(function () {
    $(this).toggleClass("slide").next(".unislider-content").toggleClass("show");
  });

  $("#show-catalog").click(function () {
    $("#extsearch-mob-container").removeClass("show-layer-container");
    $("#catalog-mob-container").toggleClass("show-layer-container");

    if ($("#catalog-mob-container").hasClass("show-layer-container")) {
      fsGoogleMetrikaEvent("show_catalog");
    }
  });

  $(".showresult-button button").click(function () {
    if ("filter-show-result" === $(this).attr("id")) {
      $("html,body").scrollTop(0);
    }
    $(".layer-container").removeClass("show-layer-container");
    $('body').removeClass('hide-jivo');

    if ("undefined" !== typeof fsAppJs) {
      fsAppJs.setRefreshEnabled(true);
    }
  });

  var opacityLayer = document.getElementById("opacity-layer");

  opacityLayer.addEventListener("click", function () {
    headerPopup.hide();
  });

  basket.init();

})(document, fsLoader, headerPopup, basket);
