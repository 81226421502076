/* global fsAppJs, goodsDetailId, fsGoogleMetrikaEvent */

var fsFavorites = new (function () {
  var _this = this;

  this.update = function (data, active) {
    var a = $("#btn-favorites");

    if (!active) {
      active = false;
    }

    //a.attr("href", data.uri);
    a.attr("data-count", data.count);
    if (0 < data.count) {
      a.addClass("full");
    } else {
      a.removeClass("full");
    }
    $(".js-favorites-count").html(data.count);

    if ("undefined" !== typeof fsAppJs) {
      if ("undefined" !== typeof goodsDetailId) {
        fsAppJs.updateFavorites(true, active, data.count);
      } else {
        fsAppJs.updateFavorites(false, false, data.count);
      }
    }
  };

  this.toggle = function (element) {
    var id, action = "add";

    id = $(element).data("id");

    if ($(element).hasClass("active")) {
      action = "remove";
    }

    $.post("/favorites/", {
      action: action,
      id: id
    }, function (data) {
      var active = false;

      if ($(element).hasClass("dotted")) { // Temp
        if ("add" === action) {
          $(element).html("Удалить из Избранного");
        } else {
          $(element).html("В Избранное");
        }
      }

      if ("add" === action) {
        active = true;
        $(".js-favorites-btn-" + id).addClass("active");
      } else {
        $(".js-favorites-btn-" + id).removeClass("active");
      }

      _this.update(data, active);

      if ("add" === action) {
        if ("function" === typeof fsGoogleMetrikaEvent) {
          fsGoogleMetrikaEvent('add_to_fav', {
            fs_goods_name: data.goodsName
          });
        }
      }
    }, "json");
  };
})();
