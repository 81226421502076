/* global headerPopup, qview, dataLayer */

var basket = new (function ($) {
  /**
   * Open popup after added to the basket.
   */
  this.autoOpen = true;
  this.isApp = false;
  this.isBasketPage = false;
  this.amount = 0;

  var _this = this, basketList, basketListButton, checkoutForm, ajaxWorking = false, refreshCounter = 0, goods = {};

  this.init = function () {
    basketList = document.querySelector(".basket_list");
    basketListButton = $(".basket-list-button").eq(0);
    checkoutForm = document.forms["checkout"];

    if (!checkoutForm) {
      send("load");
    }
  };

  this.add = function (id, specialId) {
    if (!specialId) {
      specialId = null;
    }

    send("add", id, specialId, function (data) {
      if (data.success) {
        _this.updateButtons(id);
        if ("undefined" !== typeof qview)
          qview.clearCache();
        scrollTo(id);
        var btnBasket = document.getElementById('btn-basket');
        if (btnBasket) {
          btnBasket.classList.add('full');
        }

        if (dataLayer && data.productFieldObject) {
          dataLayer.push({
            "ecommerce": {
              "add": {
                "products": [
                  data.productFieldObject
                ]
              }
            }
          });
        }
      } else {
        if (_this.isApp && !_this.isBasketPage) {
          location.href = '/basket/';
        }
      }

      if (_this.autoOpen) {
        headerPopup.show("basket");
      }
    });

  };

  this.addCoupon = function (coupon, callback) {
    send("addCoupon", null, coupon, callback);
  };

  this.refresh = function () {
    send("refresh");
  };

  this.remove = function (id) {
    delete goods[id];
    _this.updateButtons(id);
    send("remove", id, null, function (data) {
      $(".basket-goods-" + id).remove();
      if (0 === data.quantity) {
        basketEmpty();
        headerPopup.hide();
      }
      if ("undefined" !== typeof qview)
        qview.clearCache();

      if (dataLayer && data.productFieldObject) {
        dataLayer.push({
          "ecommerce": {
            "remove": {
              "products": [
                data.productFieldObject
              ]
            }
          }
        });
      }
    });
  };

  this.removeCoupon = function () {
    send("removeCoupon", null, null, basket.refresh);
  };

  this.setQuantity = function (id, value) {
    if (1 > value) {
      value = 1;
    }

    $(".basket-goods-" + id + " input.gcount").val(value);

    if (ajaxWorking)
      refreshCounter++;

    send("setQuantity", id, value);
  };

  this.plus = function (id) {
    var input = document.getElementById('basket-table').querySelector(".basket-goods-" + id + " input.gcount");
    if (input) {
      this.setQuantity(id, parseInt(input.value) + 1);
    }
  };

  this.minus = function (id) {
    var input = document.getElementById('basket-table').querySelector(".basket-goods-" + id + " input.gcount");
    if (input) {
      this.setQuantity(id, parseInt(input.value) - 1);
    }
  };

  this.toggleVase = function (element, id) {
    if ("undefined" !== typeof vasesGoodsdetail) {
      if ("undefined" !== typeof vasesGoodsdetail[id]) {
        vasesGoodsdetail[id].enabled = element.checked;
      }
    }
    if ("undefined" !== typeof vasesQuickView) {
      if ("undefined" !== typeof vasesQuickView[id]) {
        vasesQuickView[id].enabled = element.checked;
      }
    }
    if (element.checked) {
      send("addVase", id, null, function () {
        if ("function" === typeof fsGoogleMetrikaEvent) {
          fsGoogleMetrikaEvent('add_vase');
        }
      });
    } else {
      send("removeVase", id);
    }
  };

  this.toggleWetPack = function (element, id) {
    if ("undefined" !== typeof wetPacksGoodsdetail) {
      if ("undefined" !== typeof wetPacksGoodsdetail[id]) {
        wetPacksGoodsdetail[id].enabled = element.checked;
      }
    }
    if ("undefined" !== typeof wetPacksQuickView) {
      if ("undefined" !== typeof wetPacksQuickView[id]) {
        wetPacksQuickView[id].enabled = element.checked;
      }
    }
    if (element.checked) {
      send("addWetPack", id, null, function () {
        if ("function" === typeof fsGoogleMetrikaEvent) {
          fsGoogleMetrikaEvent('add_wet_pack');
        }
      });
    } else {
      send("removeWetPack", id);
    }
  };

  this.updateButtons = function (id) {
    $(".js-basket-btn-" + id).each(function () {
      var button = this;
      if ("undefined" !== typeof goods[id]) {
        button.innerHTML = "В корзине";
        button.classList.add("orng");
      } else {
        if (button.dataset.isMono) {
          button.innerHTML = "Выбрать размер";
        } else {
          if (button.classList.contains("preorder")) {
            button.innerHTML = "Предзаказ";
          } else if (button.classList.contains("special")) {
            button.innerHTML = "Заказать";
          } else {
            button.innerHTML = "В корзину";
          }
        }
        button.classList.remove("orng");
      }
    });
  };

  this.useBonus = function (element) {
    if (element.checked) {
      send("addBonus");
    } else {
      send("removeBonus");
    }
  };

  function basketEmpty() {
    var basketResultElement, basketCheckoutButton, basketContinueLink, btnBasket;

    basketResultElement = document.getElementById('basket-result');
    basketCheckoutButton = document.querySelector('#basket-buttons .js-basket-checkout');
    basketContinueLink = document.querySelector('#basket-buttons .js-basket-continue');
    btnBasket = document.getElementById('btn-basket');

    if (basketResultElement) {
      basketResultElement.style.display = "none";
    }
    if (basketCheckoutButton) {
      basketCheckoutButton.style.display = "none";
    }
    if (basketContinueLink) {
      basketContinueLink.classList.remove("invert");
      basketContinueLink.classList.remove("orng");
      basketContinueLink.classList.add("targetbut");
    }
    if (btnBasket) {
      btnBasket.classList.remove('full');
    }
  }

  function scrollTo(id) {
    var basketTable = document.getElementById("basket-table");
    if (basketTable) {
      var basketRow = basketTable.querySelector(".basket-goods-" + id);
      if (basketRow) {
        var top = 0;
        if ($('#overnavbar').length > 0) {
          top = $('#overnavbar').height();
          top += 5;
        }
        $("html,body").animate({scrollTop: $($(basketRow)).offset().top - top}, 500);
      }
    }
  }

  function getRadioValue(elements) {
    var value = '';

    for (var i = 0; i < elements.length; i++) {
      var element = elements[i];

      if (element.checked && !element.disabled) {
        value = element.value;
      }
    }

    return value;
  }

  function send(action, id, value, callback) {
    ajaxWorking = true;

    var data = {action: action};
    data.id = id;
    data.value = value;
    data.isCheckout = false;

    if (checkoutForm) {
      data.selfdelivery = getRadioValue(checkoutForm.elements["selfdelivery"]);
      data.delcity = checkoutForm.elements["delcity"].value;
      data.deldate = checkoutForm.elements["deldate"].value;
      data.selfdeldate = checkoutForm.elements["selfdeldate"].value;
      data.deltime = checkoutForm.elements["deltime"].value;
      data.rec_type = getRadioValue(checkoutForm.elements["rec_type"]);
      data.leave_other = getRadioValue(checkoutForm.elements["leave_other"]);
      data.photo_rec = checkoutForm.elements["photo_rec"].checked ? 1 : 0;
      data.exact_time = checkoutForm.elements["exact_time"].disabled ? 0 : (checkoutForm.elements["exact_time"].checked ? 1 : 0);
      data.ded_moroz = checkoutForm.elements["ded_moroz"] ? (checkoutForm.elements["ded_moroz"].checked ? 1 : 0) : 0;

      /* Доставка к точному времени */
      if (1 === data.exact_time) {
        data.exact_hh = checkoutForm.elements["exact_hh"].value;
      }
    }

    var basketHolder = document.getElementById("basket-table");
    if (basketHolder) {
      data.getBasketHtml = true;
    }

    $.post("/ajax-basket/", data, function (data) {
      _this.amount = data.amount;
      goods = data.goods;

      if (0 < refreshCounter) {
        refreshCounter--;
        return;
      }

      $("#btn-basket").attr("data-count", data.quantity);

      if ("undefined" !== typeof fsAppJs) {
        fsAppJs.updateBasket(data.quantity);
      }

      var basketQuantity = document.querySelector(".js-basket-quantity");
      if (basketQuantity) {
        basketQuantity.innerHTML = data.quantity;
      }

      $(".js-basket-sum").html(data.summarySum);
      $(".js-basket-sum-usd").html(data.summarySumUsd);
      $(".js-basket-sum-eur").html(data.summarySumEur);

      var regDiscountElement = $("#fs-reg-discount");
      if (regDiscountElement) {
        var regDiscountSumElement = $("#fs-reg-discount-sum");

        regDiscountSumElement.html(data.regDiscount);

        if (!data.regDiscount) {
          regDiscountElement.hide();
        } else {
          regDiscountElement.show();
        }
      }

      var basketPopupElement = document.getElementById("js-basket-popup");
      if (basketPopupElement) {
        basketPopupElement.innerHTML = data.popupHtml;
      }

      if ("load" !== action) {
        if (basketHolder) {
          basketHolder.innerHTML = data.basketHtml;
        }
      }

      for (var gid in goods) {
        if (goods[gid].isCard) {
          var cardTextElement = document.getElementById("basket-card-text-" + gid);
          if ("object" === typeof editCardText && cardTextElement) {
            editCardText.restoreValue(cardTextElement);
          }
        }
        if ("load" === action) {
          _this.updateButtons(gid);
        }
      }

      if ("function" === typeof callback) {
        callback(data);
      }

      ajaxWorking = false;
    }, "json");
  }

})(jQuery);
