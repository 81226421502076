/* global dataLayer, ym, fsLoader, fsWebAnalyticsEvents */

function $$$(element) {
  if ("string" === typeof element) {
    return document.getElementById(element);
  }

  return element;
}

function yaMetrikaHit(url, title) {
  if ("function" === typeof ym) {
    ym(22536634, 'hit', url, {
      title: title,
      referer: document.location.href
    });
    console.log('yaMetrikaHit(url: ' + url + ' title: ' + title + ')');
    return true;
  }
  return false;
}

function yaMetrikaReachGoal(target) {
  if ("function" === typeof ym) {
    ym(22536634, 'reachGoal', target);
  }
}

/**
 * Отправляет событие в Google
 *
 * @param eventName
 */
function sendGoogleEvent(eventName) {
  dataLayer.push({'event': eventName});
}

/**
 * Отправляет событие в Tag Manager
 *
 * @param category
 * @param action
 * @param label
 */
function googleTrackEvent(category, action, label) {
  dataLayer.push({'event': 'trackEvent', 'eventCategory': category, 'eventAction': action, 'eventLabel': label});
}

function fsGoogleMetrikaEvent(eventName, params) {
  if ('undefined' === typeof params) {
    params = {};
  }

  if ("function" === typeof ym) {
    ym(22536634, 'reachGoal', eventName, params, function () {
      console.log('reachGoal: ' + eventName, params);
    });
  }

  if ("object" === typeof dataLayer) {
    dataLayer.push({'event': 'trackEvent', 'eventCategory': eventName, 'eventAction': eventName, 'eventLabel': eventName});
  }

  console.log('fsGoogleMetrikaEvent: ' + eventName);
}

function initFancyBox() {
  $.extend($.fancybox.defaults, {
    smallBtn: false,
    protect: false,
    infobar: false,
    hash: false,
    idleTime: false,
    mobile: {
      clickOutside: function (current, event) {
        return current.type === "image" ? "toggleControls" : "close";
      }
    },
    buttons: [
      "close"
    ],
    lang: "ru", i18n: {
      ru: {
        CLOSE: "Закрыть",
        NEXT: "След.",
        PREV: "Пред.",
        ERROR: "Запрашиваемый контент не может быть загружен. <br/> Пожалуйста, перезагрузите страницу.",
        PLAY_START: "Воспроизведение",
        PLAY_STOP: "Пауза",
        FULL_SCREEN: "Во весь экран",
        THUMBS: "Превью",
        DOWNLOAD: "Загрузить оригинал",
        SHARE: "Поделиться",
        ZOOM: "Увеличить"
      }
    }
  });
}

function assignFancyToGroups() {

}

function assignComponentsFancybox() {

}

function assignPreorderFancybox() {

}

function assignFancyCalc() {

}

function fancyToPictures() {

}

var editCardText = new (function ($) {
  var _this = this;

  this.localStorageKey = "fs_edit_card_text";

  this.onInput = function (element) {
    save(element);
  };

  this.onChange = function (element) {
    save(element);

    if ("function" === typeof fsGoogleMetrikaEvent) {
      fsGoogleMetrikaEvent('card_text_change');
    }
  };

  this.remove = function (goodsId) {

  };

  this.removeAll = function () {
    window.localStorage.removeItem(this.localStorageKey);
  };

  this.restoreValue = function (element) {
    var values = this.getValues();

    var goodsId = $(element).data("goods-id");

    element.value = values[goodsId] ? values[goodsId] : "";
  };

  this.getValues = function () {
    var values = {}, data;

    data = window.localStorage.getItem(this.localStorageKey);

    if (data) {
      values = JSON.parse(data);
    }

    return values;
  };

  function save(element) {
    var goodsId = $(element).data("goods-id"),
            text = element.value;

    var values = _this.getValues();

    values[goodsId] = text;

    var data = JSON.stringify(values);

    window.localStorage.setItem(_this.localStorageKey, data);
  }
})(jQuery);

function mfVariantSelected(qview, monoVariantsLocal, flowerPictureAlt) {
  if ($("#piecesGoods").length == 0 && $("#piecesQV").length == 0) {
    return;
  }
  if (typeof qview != "undefined" && qview == true) {
    var mainObject = $("#qvpopup");
  } else {
    //var mainObject = $("#item_detail");
    var mainObject = $("#goodswide");
    qview = false;
  }

  var checkedVariant = mainObject.find('.pieces').find('input[type=radio]:checked,option:selected').prop('id').substr(8),
          flowerArticle = mainObject.find('.monoArticle'),
          flowerPicture = mainObject.find('.b_item').find('img'),
          //flowerPreview = mainObject.find('.item-var-preview > a:first-child'),
          //flowerPreviewLink = mainObject.find('.item-var-preview > a:first-child'),
          flowerPrice = mainObject.find('.price'),
          flowerPriceMarch8 = mainObject.find('.price-march-8'),
          flowerPriceValentine = mainObject.find('.price-valentine'),
          flowerPriceDiscount = mainObject.find('.price_discont').find('span'),
          metaPrice = mainObject.find('div[itemprop=offers]').find('meta[itemprop=price]'),
          flowerOrder = mainObject.find('.buynow').eq(0).find('button'),
          flowerCompare = mainObject.find('.tocompare'),
          flowerSaved = mainObject.find('.saved'),
          flowerHeight = mainObject.find('#py,.prop_height'),
          flowerDiameter = mainObject.find('#px,.prop_diameter'),
          flowerWeight = mainObject.find('.prop_weight'),
          flowerQuantity = mainObject.find('span.quantity'),
          descriptionLink = mainObject.find("#descriptionLink");

  if (dataLayer) {
    dataLayer.push({
      "ecommerce": {
        "detail": {
          "products": [
            monoVariantsLocal[checkedVariant].ecommerce
          ]
        }
      }
    });
  }

  if (!qview) {
    window.location.hash = monoVariantsLocal[checkedVariant].ident;
  }

  if ($("#owl_quickview").length > 0) {
    flowerPicture.prop('src', '/img/blank-flower-480.gif'); // сброс. Покажется прелоадер.
    flowerPicture.prop('src', '/images/goods/res4/' + monoVariantsLocal[checkedVariant].article + '.jpg');
  } else {
    var lz_picture = document.createElement('picture');
    var lz_picture_source0 = document.createElement('source');
    var lz_picture_source1 = document.createElement('source');
    var lz_picture_img = document.createElement('img');

    lz_picture_source0.type = 'image/webp';
    lz_picture_source0.srcset = '/images/goods/res4_webp/' + monoVariantsLocal[checkedVariant].article + '.webp';

    lz_picture_source1.type = 'image/jpeg';
    lz_picture_source1.srcset = '/images/goods/res4/' + monoVariantsLocal[checkedVariant].article + '.jpg';

    lz_picture_img.src = '/images/goods/res4/' + monoVariantsLocal[checkedVariant].article + '.jpg';

    lz_picture.appendChild(lz_picture_source0);
    lz_picture.appendChild(lz_picture_source1);
    lz_picture.appendChild(lz_picture_img);

    lz_picture_img.onload = function () {
      flowerPictureAlt = ((flowerPictureAlt) ? flowerPictureAlt + '. ' : '') + 'Вариант из ' + monoVariantsLocal[checkedVariant].quantity;

      mainObject.find(".js-jComment picture source").eq(0).attr("srcset", lz_picture_source0.srcset);
      mainObject.find(".js-jComment picture source").eq(1).attr("srcset", lz_picture_source1.srcset);

      if (lz_picture_img.currentSrc) {
        mainObject.find(".js-jComment img").attr("src", lz_picture_img.currentSrc);
      } else {
        mainObject.find(".js-jComment img").attr("src", lz_picture_img.src);
      }

      if (cCommentInit) {
        cCommentInit('/images/goods/bigp/' + monoVariantsLocal[checkedVariant].article + '.jpg');
      }
    };
  }

  //flowerPreview.css('background-image', 'url(/images/goods/res0n/' + monoVariantsLocal[checkedVariant].article + '.png)');
  //flowerPreviewLink.prop('href', '/images/goods/bigp/' + monoVariantsLocal[checkedVariant].article + '.jpg');
  flowerQuantity.text(monoVariantsLocal[checkedVariant].quantity);
  if (flowerOrder.data("active") != 0) {
    flowerPrice.html(monoVariantsLocal[checkedVariant].price + '&nbsp;&#8381;');
    if (flowerPriceMarch8) {
      flowerPriceMarch8.html(monoVariantsLocal[checkedVariant].march8Price + '&nbsp;&#8381;');
    }
    if (flowerPriceValentine) {
      flowerPriceValentine.html(monoVariantsLocal[checkedVariant].valentinePrice + '&nbsp;&#8381;');
    }
    if (typeof monoVariantsLocal[checkedVariant].price_3percent === "string") {
      var jsprice3percent = $(".js-price3percent");
      if (jsprice3percent.length > 0) {
        jsprice3percent.html(monoVariantsLocal[checkedVariant].price_3percent + "&nbsp;&#8381;");
      }
    }
  }
  metaPrice.prop('content', monoVariantsLocal[checkedVariant].price.replace(' ', ''));

  //20170208
  if (descriptionLink.length > 0) {
    var descriptionArticle = String(monoVariantsLocal[checkedVariant].article).replace(/\d{1}$/, '0');
    descriptionLink.attr("href", '/' + descriptionArticle + '.html#' + monoVariantsLocal[checkedVariant].ident);
  }
  // /20170802

  flowerArticle.text(monoVariantsLocal[checkedVariant].article);
  if (0 != monoVariantsLocal[checkedVariant].dprice || 0 != monoVariantsLocal[checkedVariant].action_price) {
    var priceFrom = monoVariantsLocal[checkedVariant].price,
            priceTo = monoVariantsLocal[checkedVariant].price;
    if (0 != monoVariantsLocal[checkedVariant].action_price) {
      priceFrom = monoVariantsLocal[checkedVariant].action_price;
    }
    if (0 != monoVariantsLocal[checkedVariant].dprice) {
      priceTo = monoVariantsLocal[checkedVariant].dprice;
    }
    if (priceTo) {
      flowerPrice.html(priceTo + '&nbsp;&#8381;');
    }
    if (priceFrom > priceTo) {
      flowerPriceDiscount.html('<del>' + priceFrom + '&nbsp;&#8381;</del>').show();
    } else {
      flowerPriceDiscount.hide();
    }
  } else {
    flowerPriceDiscount.hide();
  }

  if (qview) {
    goodsQuickViewId = monoVariantsLocal[checkedVariant].goods_id;
    if (typeof vasesQuickView !== "undefined") {
      if (typeof vasesQuickView[goodsQuickViewId] !== "undefined") {
        var vase = vasesQuickView[goodsQuickViewId];
        mainObject.find(".js-vase-price").text(vase.price);
        $("#quickview-vase-input").prop("checked", vase.enabled);
      }
    }
    if (typeof wetPacksQuickView !== "undefined") {
      if (typeof wetPacksQuickView[goodsQuickViewId] !== "undefined") {
        var wetPack = wetPacksQuickView[goodsQuickViewId];
        mainObject.find(".js-wetpack-price").text(wetPack.price);
        $("#quickview-wetPack-input").prop("checked", wetPack.enabled);
      }
    }
  } else {
    goodsDetailId = monoVariantsLocal[checkedVariant].goods_id;
    if (typeof vasesGoodsdetail !== "undefined") {
      if (typeof vasesGoodsdetail[goodsDetailId] !== "undefined") {
        var vase = vasesGoodsdetail[goodsDetailId];
        mainObject.find(".js-vase-price").text(vase.price);
        $("#goodsdetail-vase-input").prop("checked", vase.enabled);
      }
    }
    if (typeof wetPacksGoodsdetail !== "undefined") {
      if (typeof wetPacksGoodsdetail[goodsDetailId] !== "undefined") {
        var wetPack = wetPacksGoodsdetail[goodsDetailId];
        mainObject.find(".js-wetpack-price").text(wetPack.price);
        $("#goodsdetail-wetPack-input").prop("checked", wetPack.enabled);
      }
    }

  }

  if (flowerOrder && 0 < flowerOrder.length) {
    flowerOrder[0].className = flowerOrder[0].className.replace(/js-basket-btn-\d+/, '');
  }

  flowerOrder.addClass('js-basket-btn-' + monoVariantsLocal[checkedVariant].goods_id);
  basket.updateButtons(monoVariantsLocal[checkedVariant].goods_id);

  flowerOrder.attr('data-id', monoVariantsLocal[checkedVariant].goods_id);
  flowerOrder.attr('data-article', monoVariantsLocal[checkedVariant].article);

  if (!qview) {
    var tab = $("#goodsTabs").find("li[data-target=tabPriceGoods]");
    if (tab.length > 0) {
      $("#tabPriceGoods").attr('data-loaded', 'false');

      tab.attr('data-url', '/ajax-goods-tabs?action=get-similar-price&id=' + monoVariantsLocal[checkedVariant].goods_id);
      if ($("#thePriceGoods").is(':visible')) {
        $("#thePriceGoods .mainCarousel").html('');
        tab.removeClass('active_tab');
        tab.click();
      }
    }
  }

  /*if (flowerOrder.data("active") != 0) {
   if (1 == monoVariantsLocal[checkedVariant].in_basket) {
   //flowerOrder.prop('href', '/basket/');
   flowerOrder.parent().addClass('ordered');
   flowerOrder.html('<ins>В корзине</ins>');
   if (qview) {
   orderHref = flowerOrder.attr('href');
   if (!/\/basket\//.test(orderHref)) {
   flowerOrder.data('oldhref', flowerOrder.attr('href'));
   }
   flowerOrder.attr('href', '/basket/');
   flowerOrder.removeAttr('onlick');
   }
   } else {
   if (qview) {
   flowerOrder.attr('href', flowerOrder.data('oldhref'));
   }
   flowerOrder.parent().removeClass('ordered');
   if (!flowerOrder.hasClass("preorder")) {
   flowerOrder.removeClass('orng').html('В корзину');
   }
   }
   }*/
  var compareButton = flowerCompare.find("a");
  if (compareButton.length === 1) {
    compareButton.attr("data-article", monoVariantsLocal[checkedVariant].article);
    compareButton.attr("data-mono-id", checkedVariant);
    if (1 == monoVariantsLocal[checkedVariant].is_compared) {
      compareButton.addClass("in_compare");
      compareButton.find("span").removeClass("dotted").addClass("underline").text("В Cравнении");
    } else {
      compareButton.removeClass("in_compare");
      compareButton.find("span").removeClass("underline").addClass("dotted").text("В Cравнение");
    }
  }
  var saveButton = flowerSaved.find("button");
  if (saveButton.length === 1) {
    saveButton.attr("data-id", monoVariantsLocal[checkedVariant].goods_id);
    if (!qview) {
      if ("undefined" !== typeof savedItems) {
        savedItems.update();
      }
    } else {
      if ("undefined" !== typeof qviewSavedItems) {
        qviewSavedItems.update();
      }
    }
  }
  if (0 > monoVariantsLocal[checkedVariant].height) {
    flowerHeight.show().html((-1 * monoVariantsLocal[checkedVariant].height) + '&nbsp;см');
  } else {
    flowerHeight.hide();
  }
  if (0 > monoVariantsLocal[checkedVariant].diameter) {
    flowerDiameter.show().html('&#8709;' + (-1 * monoVariantsLocal[checkedVariant].diameter) + '&nbsp;см');
  } else {
    if (0 > monoVariants[checkedVariant].width) {
      flowerDiameter.show().html((-1 * monoVariantsLocal[checkedVariant].width) + '&nbsp;см');
    } else {
      flowerDiameter.hide();
    }
  }
  if (0 > monoVariantsLocal[checkedVariant].weight) {
    flowerWeight.show().html(', вес - ' + ((-1 * monoVariantsLocal[checkedVariant].weight) + '').replace('.', ',') + '&nbsp;кг');
  } else {
    flowerWeight.hide();
  }

  if (!qview) {
     var flowerWhen = $('#when-cover');
  } else {
     var flowerWhen = mainObject.find(".when");
  }

  if ('' !== monoVariantsLocal[checkedVariant].deliveryHtml) {
    flowerWhen.html(monoVariantsLocal[checkedVariant].deliveryHtml).show();
  } else {
    flowerWhen.hide();
  }

  if ($("#owl_quickview").length > 0) {
    var owl = $("#owl_quickview").data('owlCarousel');
    try {
      owl.goTo(0);
    } catch (e) {
    }

  }

  flowerOrder.prop("disabled", false).removeClass("disabled");

  var mainCarousel = mainObject.find('#goodsDetailCarousel');

  if (mainCarousel) {
    if (mainCarousel.data('owlCarousel')) {
      mainCarousel.data('owlCarousel').goTo(0);
    }

    mainObject.find('.js-gdetail-additional-pic').eq(0).find('.preview').css('background-image', 'url(/images/goods/res0n/' + monoVariantsLocal[checkedVariant].article + '.png)');
  }

  return true;
}

function fsDeferEvent(target) {
  var value, array = [];

  if ("undefined" === typeof sessionStorage) {
    return false;
  }

  value = sessionStorage.getItem("fs_defer_event");

  if (value) {
    array = JSON.parse(value);
  }

  array.push(target);
  value = JSON.stringify(array);

  sessionStorage.setItem("fs_defer_event", value);

  return true;
}

function fsDeferEventSend() {
  var value = null, array = [];

  if ("undefined" !== typeof sessionStorage) {
    value = sessionStorage.getItem("fs_defer_event");
  }

  if (value) {
    array = JSON.parse(value);

    for (var i = 0; i < array.length; i++) {
      var target = array[i];
      fsGoogleMetrikaEvent(target);
    }

    sessionStorage.removeItem("fs_defer_event");
  }
}

function fsWebAnalyticsSend() {
  if (("undefined" !== typeof fsWebAnalyticsEvents) && (0 < fsWebAnalyticsEvents.length)) {
    for (var i = 0; i < fsWebAnalyticsEvents.length; i++) {
      var target = fsWebAnalyticsEvents[i];
      fsGoogleMetrikaEvent(target);
    }
  }
}

fsDeferEventSend();
fsWebAnalyticsSend();

function fsLoadFancyBox(callback) {
  if ("undefined" !== typeof $.fancybox) {
    callback();
    return;
  }

  fsLoader.addStyles(['/css/jquery.fancybox.min.css'], function () {
    fsLoader.addScripts(['/js/fancybox3/jquery.fancybox.min.js'], function () {
      initFancyBox();
      callback();
    });
  });
}
