var fsLoader = new (function () {
  var handlers = {}, queue = {};

  this.addStyles = function (files, callback) {
    var link;
    for (var i = 0, length = files.length; length > i; i++) {
      link = document.createElement("link");
      link.href = files[i];
      link.rel = "stylesheet";
      if (i === length - 1) { // last
        link.onload = callback;
      }
      document.head.appendChild(link);
    }
    return this;
  };

  this.addScripts = function (files, callback) {
    var script;
    for (var i = 0, length = files.length; length > i; i++) {
      script = document.createElement("script");
      script.src = files[i];
      script.async = false;
      if (i === length - 1) { // last
        script.onload = callback;
      }
      document.head.appendChild(script);
    }
    return this;
  };

  this.setHandler = function (name, handler) {
    handlers[name] = handler;

    for (var i = 0, length = queue[name].length; length > i; i++) {
      handlers[name](queue[name][i]);
    }

    queue[name] = [];
  };

  this.delegate = function (event, name) {
    if ("undefined" === typeof handlers[name]) {
      handlers[name] = false;
      queue[name] = [];
      queue[name].push(event);
      this.addScripts(["/js/dest/" + name + ".min.js"], function () {});
    } else if (false === handlers[name]) {
      queue[name].push(event);
    } else {
      handlers[name](event);
    }
  };
});
